<template>
  <div class="container-fluid">
    <role-form
      :loading="loading"
      :roleData="role"
      :formErrors="formErrors"
      @roleSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultRole from "../defaultRole";
import RoleForm from "../partials/RoleForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    RoleForm,
  },

  mixins: [alertLeave],

  data() {
    return {
      role: cloneDeep(defaultRole),
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    async handleSubmit(role) {
      this.loading = true;

      const roleData = cloneDeep(role);
      delete roleData.id;

      try {
        await this.$store.dispatch("roles/add", roleData);
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("ROLES.ROLE_ADDED"),
        });
        const role = await this.$store.getters["roles/role"];
        this.$emit("onViewRole", role, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
